import React from "react";
import "./App.css";
import Sidebar from "./components/SideBar";

import { Route, Routes, useLocation, useParams } from "react-router-dom";
import Offers from "./components/Offers";
import Applicant from "./components/Applicant";
import Contact from "./components/Contact";
import { GlobalStyle } from "./components/styles/Offers.styled";
import DetailsOffer from "./components/DetailsOffer";
import AddOffers from "./components/AddOffers";
import EditOffer from "./components/EditOffer";
import Dashboard from "./components/Dashboard";
import DetailsApplicant from "./components/DetailsApplicant";
import DetailsContact from "./components/DetailsContact";
import Sign from "./components/Sign";
import { Helmet } from "react-helmet";
import { isAuthenticated } from "./utils/auth";
import Verify from "./components/Verify";

function App() {
  const isAuthenticatedUser = isAuthenticated();
  const location = useLocation();
  const isVerifyRoute = location.pathname === "/verify";

  if (isVerifyRoute) {
    return <Verify />;
  }

  // If the user is not authenticated, render the Sign component
  if (!isAuthenticatedUser && !isVerifyRoute) {
    return <Sign />;
  }

  return (
    <div>
      <Helmet>
        {/* Add the link to your global styles here */}
        <link
          rel="stylesheet"
          type="text/css"
          href="./components/styles/Offers.styled.js"
        />
      </Helmet>
      <GlobalStyle />
      <Sidebar />
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/offres" element={<Offers />} />
        <Route path="/AddOffers" element={<AddOffers />} />
        <Route path="/EditOffers/:id/edit" element={<Modifier />} />
        <Route path="/applicant" element={<Applicant />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/offres/:id" element={<JobDetails />} />
        <Route path="/applicant/:id" element={<AppDetails />} />
        <Route path="/contact/:id" element={<ContactDetails />} />
        {/*<Route path="/services/:id" element={<Child />} />*/}
      </Routes>
    </div>
  );
}

function JobDetails() {
  let { id } = useParams();
  console.log(id);
  return <div>{<DetailsOffer id={id} />}</div>;
}

function Modifier() {
  let { id } = useParams();
  console.log(id);
  return <div>{<EditOffer id={id} />}</div>;
}

function AppDetails() {
  let { id } = useParams();
  console.log(id);
  return <div>{<DetailsApplicant id={id} />}</div>;
}

function ContactDetails() {
  let { id } = useParams();
  console.log(id);
  return <div>{<DetailsContact id={id} />}</div>;
}

export default App;
