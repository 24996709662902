import React, { useState, useEffect } from "react";
import { DetailsContainer, Panel, ReturnButton } from "./styles/Offers.styled";
import { Link, useNavigate } from "react-router-dom";

const DetailsOffer = (props) => {
  const [detailOffer, setDetailOffer] = useState(null);
  const [selectedIds, setSelectedIds] = useState(props || []);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDetailOffer = async () => {
      const { id } = props;

      try {
        const response = await fetch(
          `https://offers.somadsi.ma/wp-content/plugins/custom-offer-plugin/fetch-data.php?param=offers&id=${id}`
        );
        const jsonData = await response.json();
        setDetailOffer(jsonData[0]);
      } catch (error) {
        console.error("Error fetching detail data:", error);
      }
    };

    fetchDetailOffer();
  }, [props]);

  const deleteSelectedRows = async () => {
    console.log("Selected IDs:", selectedIds.id);

    if (selectedIds !== 0) {
      const confirmDelete = window.confirm(
        "Êtes-vous sûr de vouloir supprimer les lignes sélectionnées ?"
      );

      if (confirmDelete) {
        // Placeholder logic to delete selected rows
        fetch(
          "https://offers.somadsi.ma/wp-content/plugins/custom-offer-plugin/delete-data.php",
          {
            method: "POST", // Change to 'POST' if necessary
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              ids: selectedIds,
              param: "offers",
              sentFrom: "details",
            }),
          }
        )
          .then((response) => response.json())
          .then((data) => {
            console.log("Response from server:", data);
            // Update the UI or perform additional actions as needed
            // For demonstration purposes, let's just log the IDs that were deleted
            console.log("Deleted rows with IDs:", selectedIds);
            // Clear the selection
            setSelectedIds([]);
            navigate("/offres");
          })
          .catch((error) => console.error("Error deleting rows:", error));
      }
    } else {
      alert("Veuillez sélectionner au moins une ligne à supprimer.");
    }
  };

  if (!detailOffer) {
    return <div>Loading...</div>;
  }

  return (
    <DetailsContainer>
      <Panel>
        <div className="panel-header">
          <h1>Details d'offre:</h1>
        </div>
        <div className="panel-button">
          <Link to={"/AddOffers"}>
            <button className="button" id="ajouter" style={{ display: "none" }}>
              <svg
                id="icon"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M7.007 12a.75.75 0 0 1 .75-.75h3.493V7.757a.75.75 0 0 1 1.5 0v3.493h3.493a.75.75 0 1 1 0 1.5H12.75v3.493a.75.75 0 0 1-1.5 0V12.75H7.757a.75.75 0 0 1-.75-.75"
                />
                <path
                  fill="currentColor"
                  fillRule="evenodd"
                  d="M7.317 3.769a42.502 42.502 0 0 1 9.366 0c1.827.204 3.302 1.643 3.516 3.48c.37 3.157.37 6.346 0 9.503c-.215 1.837-1.69 3.275-3.516 3.48a42.5 42.5 0 0 1-9.366 0c-1.827-.205-3.302-1.643-3.516-3.48a40.903 40.903 0 0 1 0-9.503c.214-1.837 1.69-3.276 3.516-3.48m9.2 1.49a41.001 41.001 0 0 0-9.034 0A2.486 2.486 0 0 0 5.29 7.424a39.402 39.402 0 0 0 0 9.154a2.486 2.486 0 0 0 2.193 2.164c2.977.332 6.057.332 9.034 0a2.486 2.486 0 0 0 2.192-2.164a39.401 39.401 0 0 0 0-9.154a2.486 2.486 0 0 0-2.192-2.163"
                  clipRule="evenodd"
                />
              </svg>
              Ajouter
            </button>
          </Link>
          <Link to={`/EditOffers/${detailOffer.id}/edit`}>
            <button
              className="button"
              id="modifier"
              style={{ display: "inline" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  fill="white"
                  fillRule="evenodd"
                  d="M21.455 5.416a.75.75 0 0 1-.096.943l-9.193 9.192a.75.75 0 0 1-.34.195l-3.829 1a.75.75 0 0 1-.915-.915l1-3.828a.778.778 0 0 1 .161-.312L17.47 2.47a.75.75 0 0 1 1.06 0l2.829 2.828a.756.756 0 0 1 .096.118m-1.687.412L18 4.061l-8.518 8.518l-.625 2.393l2.393-.625z"
                  clipRule="evenodd"
                />
                <path
                  fill="white"
                  d="M19.641 17.16a44.4 44.4 0 0 0 .261-7.04a.403.403 0 0 1 .117-.3l.984-.984a.198.198 0 0 1 .338.127a45.91 45.91 0 0 1-.21 8.372c-.236 2.022-1.86 3.607-3.873 3.832a47.77 47.77 0 0 1-10.516 0c-2.012-.225-3.637-1.81-3.873-3.832a45.922 45.922 0 0 1 0-10.67c.236-2.022 1.86-3.607 3.873-3.832a47.75 47.75 0 0 1 7.989-.213a.2.2 0 0 1 .128.34l-.993.992a.402.402 0 0 1-.297.117a46.164 46.164 0 0 0-6.66.255a2.89 2.89 0 0 0-2.55 2.516a44.421 44.421 0 0 0 0 10.32a2.89 2.89 0 0 0 2.55 2.516c3.355.375 6.827.375 10.183 0a2.89 2.89 0 0 0 2.55-2.516"
                  clipRule="evenodd"
                />
              </svg>
              Modifier
            </button>
          </Link>
          <button
            className="button"
            id="supprimer"
            onClick={deleteSelectedRows}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
            >
              <path
                fill="white"
                d="M7 21q-.825 0-1.412-.587T5 19V6H4V4h5V3h6v1h5v2h-1v13q0 .825-.587 1.413T17 21zM17 6H7v13h10zM9 17h2V8H9zm4 0h2V8h-2zM7 6v13z"
                clipRule="evenodd"
              />
            </svg>
            Supprimer
          </button>
        </div>
      </Panel>

      <Link to="/offres">
        <ReturnButton>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
          >
            <g fill="none" fillRule="evenodd">
              <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
              <path
                fill="currentColor"
                d="M2.614 5.426A1.5 1.5 0 0 1 4 4.5h10a7.5 7.5 0 1 1 0 15H5a1.5 1.5 0 0 1 0-3h9a4.5 4.5 0 1 0 0-9H7.621l.94.94a1.5 1.5 0 0 1-2.122 2.12l-3.5-3.5a1.5 1.5 0 0 1-.325-1.634"
              />
            </g>
          </svg>
        </ReturnButton>
      </Link>
      <input type="hidden" id="current-id" />
      <div id="jobDetails">
        <span className="header-data">Titre</span>
        <span className="body-data">{detailOffer.titre}</span>
        <span className="header-data">Tag</span>
        <span className="body-data">{detailOffer.tag}</span>
        <span className="header-data">Description</span>
        <span className="body-data">{detailOffer.description}</span>
        <span className="header-data">Experience</span>
        <span className="body-data">{detailOffer.experience}</span>
        <span className="header-data">Heure Travail</span>
        <span className="body-data">{detailOffer.heure_travail}</span>
        <span className="header-data">Ville</span>
        <span className="body-data">{detailOffer.ville}</span>
        <span className="header-data">Langue</span>
        <span className="body-data">{detailOffer.langue}</span>
        <span className="header-data">Necessite</span>
        <span
          className="body-data-N"
          dangerouslySetInnerHTML={{ __html: detailOffer.necessite }}
        ></span>
      </div>
    </DetailsContainer>
  );
};

export default DetailsOffer;
