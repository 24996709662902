import styled from "styled-components";

import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  /* Add the font-face rule directly */
  @font-face {
    font-family: 'Expletus Sans';
    src: url('https://fonts.cdnfonts.com/css/expletus-sans-2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }

  /* Resetting styles and box-sizing */
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Expletus Sans', sans-serif; /* Use the font-family you defined */
  }
`;
export const Panel = styled.div`
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 110px;

  a {
    text-decoration: none;
  }

  button {
    display: flex;
    font-weight: normal;
    /*background-color: #ff8000;*/
    color: white;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 10px;
    width: 150px;
    transition: background-color 0.3s;
    padding: 4px 20px;
    margin-top: 6px;
    justify-content: center;
  }

  h1 {
    margin-bottom: 0px;
  }

  .panel-button {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .button {
    display: flex;
    align-items: center;
    padding: 5px;
    margin: 10px;
    text-align: center;
    background-color: #4caf50;
    color: white;
    text-decoration: none;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  #modifier {
    margin-top: -36px;
    display: none;
  }

  svg {
    margin: 0 10px 0 0;
    width: 1rem;
    height: 1rem;
  }

  #ajouter {
    background-color: #86b6f6;
    text-decoration: none;
  }

  #modifier {
    background-color: #9ade7b;
  }

  #supprimer {
    background-color: #ff6969;
  }

  #supprimer:hover,
  #modifier:hover,
  #ajouter:hover {
    background-color: #000000;
  }

  .panel-header h1 {
    color: #333;
    font-size: 20px;
  }

  @media screen and (max-width: 767px) {
    display: flex;
    align-items: center;
    width: 100%;
    /* Make buttons full-width on small screens */
    margin-top: 10px;

    #modifier {
      margin-top: -33.5px;
      /*display: none;*/
    }

    #ajouter,
    #modifier,
    #supprimer {
      padding: 6px;
    }

    #supprimer svg {
      margin: 0 0 0 0;
      width: 1rem;
      height: 1rem;
    }

    h1 {
      font-size: 12px;
      margin-right: 20px;
    }

    button {
      display: flex;
      align-items: center;
      width: 90px;
      margin: 5px;
      font-size: 11px;
      padding: 10px 0px 10px 0px;
      justify-content: center;
    }

    svg {
      margin: 0px 0px 0px 10px;
      /* Adjust margin for better spacing */
      width: 1rem;
      height: 1rem;
    }
  }
`;

export const JobTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  /* Add margin for better spacing */
  cursor: pointer;
  font-size: 12px;
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.2);
  user-select: none;

  th,
  td {
    border: 1px solid #b6bbc4;
    padding: 5px;
    flex: 1 1 20%;
    text-align: center;
  }

  th {
    background-color: #333;
    color: white;
    text-transform: uppercase;
    font-size: 12px;
  }

  td {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tr:hover {
    background-color: #ddd;
  }

  @media screen and (max-width: 767px) {
    display: block;

    thead,
    tbody,
    thead th {
      display: block;
    }

    thead th:last-child {
      border-bottom: none;
    }

    thead {
      float: left;
    }

    tbody {
      width: auto;
      position: relative;
      overflow-x: auto;
    }

    td,
    th {
      padding: 13px 10px 10px 10px;
      height: 60px;
      vertical-align: middle;
      box-sizing: border-box;
      overflow-x: hidden;
      overflow-y: hidden;
      width: 100%;
      /* font-size: 13px; */
      text-overflow: ellipsis;
    }

    thead th {
      text-align: left;
      border-bottom: 1px solid #f7f7f9;
    }

    tbody tr {
      display: table-cell;
    }

    tbody tr:nth-child(odd) {
      background: none;
    }

    tr:nth-child(even) {
      background: transparent;
    }

    tr td:nth-child(odd) {
      background: #f8f8f8;
      border-right: 1px solid #e6e4e4;
    }

    tr td:nth-child(even) {
      border-right: 1px solid #e6e4e4;
    }

    tbody td {
      display: block;
      text-align: center;
    }
  }
`;

export const DetailsContainer = styled.div`
  padding: 40px;

  h3 {
    margin-top: 15px;
  }

  #jobDetails {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 10px;
    background-color: #fff;
    margin-top: 10px;
    padding-bottom: 10px;
    justify-content: center;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.2);
  }

  .header-data {
    color: #757e89;
    font-size: 12px;
    margin: 5px;
    padding: 10px;
  }

  .body-data {
    display: block;
    color: black;
    font-size: 15px;
    padding: 0 10px 10px 10px;
    margin-bottom: 40px;
  }

  .body-data-N:last-child {
    font-size: 13px;
    margin: 10px;
    padding: 30px;
    background-color: #f6f7fb;
    border: 1px solid #dde1e5;
  }

  @media screen and (max-width: 767px) {
    padding: 5px;

    .header-data,
    .body-data {
      font-size: 13px;
      /* Adjust font size for better readability on small screens */
    }

    .body-data {
      display: block;
      color: black;
      margin-bottom: 20px;
    }

    .body-data:last-child {
      font-size: 12px;
      /* Adjust font size for the last child on small screens */
      padding: 20px;
      /* Adjust padding for better spacing */
    }
  }
`;

export const Container = styled.div`
  max-width: 90%;
  margin: auto;
  margin-left: 7%;
  padding: 20px;

  h1 {
    text-align: center;
    margin: -30px 0 50px 0;
    font-size: 35px;
    color: #607274;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
  }

  h1::before {
    display: block;
    height: 4px;
    width: 50px;
    background-color: #ff6f61;
    margin-bottom: 10px;
  }

  label {
    color: #191919;
    font-size: 11px;
    margin-bottom: 5px;
  }

  input,
  textarea {
    width: 100%;
    padding: 10px;
    font-size: 12px;
    margin-bottom: 30px;
    outline-color: #b6c4b6;
    border-radius: 5px;
    border: 1px solid #cccccc;
  }

  #necessite {
    background-color: white;
    width: 100%;
    font-size: 12px;
    margin-bottom: 30px;
    border-color: #b6c4b6;
  }

  .save-div {
    display: flex;
    flex-direction: row-reverse;
  }

  #save {
    display: inline-flex;
    font-weight: normal;
    background-color: #ff8000;
    color: white;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 12px;
    width: 150px;
    transition: background-color 0.3s;
    padding: 4px 30px;
    margin-top: 6px;
  }

  #icon {
    margin: 0 10px 0 0;
    width: 1rem;
    height: 1rem;
  }

  #save:hover {
    background-color: black;
  }

  @media screen and (max-width: 768px) {
    margin: 15px;
    padding: 0;
    max-width: 100%;

    h1 {
      font-size: 12px;
      text-align: center;
      margin: -22px -17px 16px 0;
      color: #607274;
      font-weight: 600;
      margin-bottom: 20px;
    }
  }
`;

export const ReturnButton = styled.button`
  margin-top: 10px;
  background-color: transparent;
  border: 2px solid #ff8000;
  border-radius: 20%;
  color: #ff8000;
  padding: 3px 10px 5px 10px;
  transition: background-color 0.3s, outline 0.3;

  &:hover {
    background-color: #ff8000;
    color: white;
  }

  @media screen and (max-width: 768px) {
    margin-top: 0px;
    background-color: transparent;
    border: 2px solid #ff8000;
    border-radius: 20%;
    color: #ff8000;
    padding: 0px 9px 2px 9px;

    svg {
      width: 15px;
      height: 16px;
    }
  }
`;

export const BodyContainer = styled.div`
  display: flex;
  position: fixed;
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100%;
  background: #ff8000;
`;

export const SignContainer = styled.div`
  margin: auto;
  max-width: 500px;
  width: 450px;
  margin-top: 10%;

  .sign-header {
    display: flex;
  }
  .btn-sign {
    width: 50%;
    border-style: none;
    color: black;
    background: whitesmoke;
    outline: none;
    border-radius: 0px;
    border-bottom: 1px solid gray;
    text-align: center;
  }

  .btn-sign p {
    display: inline-block;
    margin: 0;
    padding: 15px;
    cursor: pointer;
  }

  .btn-sign p:hover {
    color: white;
  }

  .btn-sign:hover {
    background: #3f3f44;
  }

  .login-container {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 100%;
    padding: 35px;
    background: whitesmoke;
    height: 400px;
    border-radius: 0 0 20px 20px;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.4);
    transition: 0.3s;
    text-align: center;
  }

  .signup-container {
    margin: auto;
    width: 100%;
    padding: 35px;
    background: whitesmoke;
    height: 400px;
    border-radius: 0 0 20px 20px;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.4);
    transition: 0.3s;
    text-align: center;
  }

  .login-button,
  .signup-button {
    border-radius: 5px;
    font-size: 12px;
    padding: 5px 15px;
    background: #ff8000;
    color: white;
    outline: none;
    cursor: pointer;
    box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, 0.1);
    transition: 0.3s;
    font-weight: bold;
    border-style: none;
    margin-top: 25px;
  }

  .login-button:hover,
  .signup-button:hover {
    background: black;
    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px 2px 5px;
  }

  .login-input,
  .signup-input {
    width: 100%;
    height: 30px;
    border-radius: 5px;
    padding: 10px;
    border-color: white;
    border-width: 0px;
    border-style: none;
    outline: none;
  }

  .login-wrapper,
  .signup-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
  }

  .input-label {
    padding-right: 250px;
    margin-top: 5px;
    font-size: small;
  }
  @media only screen and (max-width: 767px) {
    width: 90%;

    .login-container,
    .signup-container {
      width: 100%;
      padding: 20px;
      height: 330px;
      border-radius: 0 0 10px 10px;
    }

    .btn-sign p {
      padding: 10px 25px;
    }
  }
`;

export const VerifyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 50px;
  width: 100%;
  height: 100vh;
  background: whitesmoke;
  font-family: "Poppins", sans-serif;
  img {
    width: 100px;
  }
  p {
    font-size: 12px;
    text-align: center;
  }

  button {
    position: relative;
    background: #444;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    border: none;
    letter-spacing: 0.1rem;
    font-size: 12px;
    padding: 10px 2rem;
    transition: 0.2s;
  }

  button:hover {
    letter-spacing: 0.2rem;
    padding: 12px 2.1rem;
    background: var(--clr);
    color: var(--clr);
    /* box-shadow: 0 0 35px var(--clr); */
    animation: box 3s infinite;
  }

  button::before {
    content: "";
    position: absolute;
    inset: 2px;
    background: #272822;
  }

  button span {
    position: relative;
    z-index: 1;
  }
  button i {
    position: absolute;
    inset: 0;
    display: block;
  }

  button i::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 2px;
    left: 80%;
    top: -2px;
    border: 2px solid var(--clr);
    background: #272822;
    transition: 0.2s;
  }

  button:hover i::before {
    width: 15px;
    left: 20%;
    animation: move 3s infinite;
  }

  button i::after {
    content: "";
    position: absolute;
    width: 10px;
    height: 2px;
    left: 20%;
    bottom: -2px;
    border: 2px solid var(--clr);
    background: #272822;
    transition: 0.2s;
  }

  button:hover i::after {
    width: 15px;
    left: 80%;
    animation: move 3s infinite;
  }

  @keyframes move {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(5px);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes box {
    0% {
      box-shadow: #27272c;
    }
    50% {
      box-shadow: 0 0 25px var(--clr);
    }
    100% {
      box-shadow: #27272c;
    }
  }
`;

export const ShapeMove = styled.table`
  background: #333;
  width: 5px;
  height: 5px;
  position: relative;
  text-align: center;
  box-shadow: 0 0 2px 1px #ff8000, 0 0 2px 1px #ff8000, 0 0 2px 1px #0ff;
  border-radius: 50%;
  z-index: 100;

  &:before,
  &:after {
    content: "";
    position: absolute;

    height: 5px;
    width: 5px;
    background: #333;
    border-radius: 50%;
  }

  &:before {
    transform: rotate(30deg);
  }

  &:after {
    transform: rotate(60deg);
  }
`;

export const DashboardContainer = styled.div`
  position: relative;
  height: 100vh;
  overflow: hidden;
  background: url("../../images/back.png") no-repeat center center fixed;
  background-size: cover;
  margin-top: -4.6%;
  @media only screen and (max-width: 767px) {
    width: 100vw;
    height: 100vh;
    margin-top: -17%;
    background-image: url("../../images/Somadsi.webp");
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    background-size: calc(95vw) auto; /* Adjust the size and padding values as needed */
    background-position: 3vw 50%;
  }

  @media only screen and (max-width: 1350px) {
    margin-top: -6.5%;
  }
`;

export const BackDash = styled.div`
  background-image: url("../../images/bg_mobile.png");
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
`;

export const LineSegment = styled.line`
  stroke: black;
  stroke-width: 2;
`;
