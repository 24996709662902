import { useCallback, useEffect, useState } from "react";
import { VerifyContainer } from "./styles/Offers.styled";
import axios from "axios";
import { useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

function Verify() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const username = queryParams.get("username");
  const [verificationComplete, setVerificationComplete] = useState(false);

  // Wrap handleVerification in useCallback to memoize it
  const handleVerification = useCallback(async () => {
    try {
      // Send a request to your PHP file to update the database
      const response = await axios.post(
        "https://offers.somadsi.ma/wp-content/plugins/custom-offer-plugin/update_user.php",
        { username },
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.data.success) {
        // Database update successful
        toast.success(response.data.message);
        setVerificationComplete(true);
      } else {
        // Handle verification failure
        toast.error(response.data.message);
        // console.error(response.data.message);
      }
    } catch (error) {
      // Handle request error
      console.error("Error during verification:", error);
    }
  }, [username]);

  useEffect(() => {
    if (username && !verificationComplete) {
      // Perform the verification only once when the component mounts
      handleVerification();
    }
  }, [username, verificationComplete, handleVerification]);

  return (
    <VerifyContainer>
      <Toaster />
      <div>
        <h1>
          <img src="../images/Somadsi.webp" alt="SOMADSI"></img>
        </h1>
      </div>
      <div>
        <h2>Bienvenue sur notre page de vérification</h2>
      </div>
      <div>
        <p>
          Nous prenons la sécurité de votre compte très au sérieux. Pour
          garantir la sécurité de votre compte, une vérification est requise
          avant de continuer. Merci de votre collaboration !
        </p>
      </div>
      <div>
        <button style={{ "--clr": "#ff8000" }} onClick={handleVerification}>
          <span>VERIFIER UTILISATEUR</span>
          <i></i>
        </button>
      </div>
    </VerifyContainer>
  );
}

export default Verify;
