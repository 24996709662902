import React, { useState, useEffect } from "react";
import { DetailsContainer, Panel, ReturnButton } from "./styles/Offers.styled";
import { Link, useNavigate } from "react-router-dom";

const DetailsContact = (props) => {
  const [detailContact, setdetailContact] = useState(null);
  const [selectedIds, setSelectedIds] = useState(props || []);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchdetailContact = async () => {
      const { id } = props;

      try {
        const response = await fetch(
          `https://offers.somadsi.ma/wp-content/plugins/custom-offer-plugin/fetch-data.php?param=message&id=${id}`
        );
        const jsonData = await response.json();
        setdetailContact(jsonData[0]);
      } catch (error) {
        console.error("Error fetching detail data:", error);
      }
    };

    fetchdetailContact();
  }, [props]);

  const deleteSelectedRows = async () => {
    console.log("Selected IDs:", selectedIds.id);

    if (selectedIds !== 0) {
      const confirmDelete = window.confirm(
        "Êtes-vous sûr de vouloir supprimer les lignes sélectionnées ?"
      );

      if (confirmDelete) {
        // Placeholder logic to delete selected rows
        fetch(
          "https://offers.somadsi.ma/wp-content/plugins/custom-offer-plugin/delete-data.php",
          {
            method: "POST", // Change to 'POST' if necessary
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              ids: selectedIds,
              param: "message",
              sentFrom: "details",
            }),
          }
        )
          .then((response) => response.json())
          .then((data) => {
            console.log("Response from server:", data);
            // Update the UI or perform additional actions as needed
            // For demonstration purposes, let's just log the IDs that were deleted
            console.log("Deleted rows with IDs:", selectedIds);
            // Clear the selection
            setSelectedIds([]);
            navigate("/contact");
          })
          .catch((error) => console.error("Error deleting rows:", error));
      }
    } else {
      alert("Veuillez sélectionner au moins une ligne à supprimer.");
    }
  };

  if (!detailContact) {
    return <div>Loading...</div>;
  }

  return (
    <DetailsContainer>
      <Panel>
        <div className="panel-header">
          <h1>Details de contact:</h1>
        </div>
        <div className="panel-button">
          <button
            className="button"
            id="supprimer"
            onClick={deleteSelectedRows}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
            >
              <path
                fill="white"
                d="M7 21q-.825 0-1.412-.587T5 19V6H4V4h5V3h6v1h5v2h-1v13q0 .825-.587 1.413T17 21zM17 6H7v13h10zM9 17h2V8H9zm4 0h2V8h-2zM7 6v13z"
                clipRule="evenodd"
              />
            </svg>
            Supprimer
          </button>
        </div>
      </Panel>

      <Link to="/contact">
        <ReturnButton>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
          >
            <g fill="none" fillRule="evenodd">
              <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
              <path
                fill="currentColor"
                d="M2.614 5.426A1.5 1.5 0 0 1 4 4.5h10a7.5 7.5 0 1 1 0 15H5a1.5 1.5 0 0 1 0-3h9a4.5 4.5 0 1 0 0-9H7.621l.94.94a1.5 1.5 0 0 1-2.122 2.12l-3.5-3.5a1.5 1.5 0 0 1-.325-1.634"
              />
            </g>
          </svg>
        </ReturnButton>
      </Link>
      <input type="hidden" id="current-id" />
      <div id="jobDetails">
        <span className="header-data">Nom et Prénom</span>
        <span className="body-data">{detailContact.nom_prenom}</span>
        <span className="header-data">Email</span>
        <span className="body-data">{detailContact.email}</span>
        <span className="header-data">Message</span>
        <span className="body-data">{detailContact.message}</span>
        <span className="header-data">Date d'ajout</span>
        <span className="body-data">{detailContact.created_at}</span>
      </div>
    </DetailsContainer>
  );
};

export default DetailsContact;
