/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { JobTable, Panel } from "./styles/Offers.styled";
import { useNavigate } from "react-router-dom";

const Applicant = () => {
  const [data, setData] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const navigate = useNavigate();

  // Function to fetch data from PHP script
  // eslint-disable-next-line no-undef
  const fetchData = useCallback(async () => {
    try {
      const response = await fetch(
        "https://offers.somadsi.ma/wp-content/plugins/custom-offer-plugin/fetch-data.php?param=applicant"
      );
      const jsonData = await response.json();
      populateTable(jsonData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  });

  const populateTable = (data) => {
    setData(data);
  };

  useEffect(() => {
    // Fetch data on component mount
    fetchData();
  }, []); // Empty dependency array ensures the effect runs once when the component mounts

  useEffect(() => {
    // Fetch data whenever selectedIds change
    fetchData();
  }, [selectedIds]);

  const handleRowDoubleClick = (id) => {
    // Navigate to the detail page with the selected ID
    navigate(`/applicant/${id}`);
  };

  const handleCheckboxChange = (event, id) => {
    const isChecked = event.target.checked;

    setSelectedIds((prevSelectedIds) => {
      if (isChecked) {
        return [...prevSelectedIds, id];
      } else {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      }
    });
  };

  const deleteSelectedRows = () => {
    console.log("Selected IDs:", selectedIds);

    if (selectedIds.length > 0) {
      const confirmDelete = window.confirm(
        "Êtes-vous sûr de vouloir supprimer les lignes sélectionnées ?"
      );

      if (confirmDelete) {
        // Placeholder logic to delete selected rows
        fetch(
          "https://offers.somadsi.ma/wp-content/plugins/custom-offer-plugin/delete-data.php",
          {
            method: "POST", // Change to 'POST' if necessary
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              ids: selectedIds,
              param: "applicant",
              sentFrom: "main",
            }),
          }
        )
          .then((response) => response.json())
          .then((data) => {
            console.log("Response from server:", data);
            // Update the UI or perform additional actions as needed
            // For demonstration purposes, let's just log the IDs that were deleted
            console.log("Deleted rows with IDs:", selectedIds);
            // Clear the selection
            setSelectedIds([]);
          })
          .catch((error) => console.error("Error deleting rows:", error));
      }
    } else {
      alert("Veuillez sélectionner au moins une ligne à supprimer.");
    }
    fetchData();
  };

  return (
    <div style={{ margin: "3%" }}>
      <Panel>
        <div className="panel-header">
          <h1>Liste des applicants</h1>
        </div>
        <div className="panel-button">
          <button
            className="button"
            id="supprimer"
            onClick={deleteSelectedRows}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
            >
              <path
                fill="white"
                d="M7 21q-.825 0-1.412-.587T5 19V6H4V4h5V3h6v1h5v2h-1v13q0 .825-.587 1.413T17 21zM17 6H7v13h10zM9 17h2V8H9zm4 0h2V8h-2zM7 6v13z"
                clipRule="evenodd"
              />
            </svg>
            Supprimer
          </button>
        </div>
      </Panel>
      <div className="table-wrapper">
        <JobTable>
          <thead>
            <tr>
              <th>
                <input type="checkbox" />
              </th>
              <th>Nom Prenom</th>
              <th>Email</th>
              <th>Ville</th>
              <th>Telephone</th>
              <th>Description</th>
              <th>Offre</th>
              <th>Created At</th>
            </tr>
          </thead>
          <tbody id="tableBody">
            {data.map((rowData, index) => (
              <tr
                key={index}
                onDoubleClick={() => handleRowDoubleClick(rowData.id)}
              >
                <td>
                  <input
                    type="checkbox"
                    id={`checkbox${index}`}
                    onChange={(event) =>
                      handleCheckboxChange(event, rowData.id)
                    }
                    checked={selectedIds.includes(rowData.id)}
                  />
                </td>
                <td style={{ display: "none" }}>{rowData.id}</td>
                <td>{rowData.nom_prenom}</td>
                <td>{rowData.email}</td>
                <td>{rowData.city}</td>
                <td>{rowData.phone}</td>
                <td>{rowData.description}</td>
                <td>{rowData.offre}</td>
                <td>{rowData.created_at}</td>
              </tr>
            ))}
          </tbody>
        </JobTable>
      </div>
    </div>
  );
};

export default Applicant;
