import React, { useEffect, useState } from "react";

const ShapeWithMovingPoints = () => {
  const pointCount = 6; // Adjust the number of points
  const radius = 150; // Adjust the radius of the shape
  const imageUrl = `${process.env.PUBLIC_URL + "/images/Somadsi.webp"}`; // Replace with your image URL
  const imageUrlLogo = `${process.env.PUBLIC_URL + "/images/WhiteLogo.webp"}`; // Replace with your image URL

  const [points, setPoints] = useState([]);

  useEffect(() => {
    // Initialize points in a circular pattern
    const initialPoints = Array.from({ length: pointCount }).map((_, index) => {
      const angle = (index / pointCount) * 2 * Math.PI;
      const x = Math.cos(angle) * radius;
      const y = Math.sin(angle) * radius;
      return { x, y };
    });

    setPoints(initialPoints);

    // Set interval to update point positions
    const intervalId = setInterval(() => {
      setPoints((prevPoints) =>
        prevPoints.map((point, index) => {
          // Adjust the movement based on your requirements
          const speed = 1;
          const angle = (index / pointCount) * 2 * Math.PI;
          const newX = point.x + Math.cos(angle) * speed;
          const newY = point.y + Math.sin(angle) * speed;
          return { x: newX, y: newY };
        })
      );
    }, 50);

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures the effect runs once on mount

  if (points.length === 0) {
    return null; // Return null while points are being initialized
  }

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <svg width="100%" height="100%" style={{ position: "absolute" }}>
        {/* Render the shape with curve lines */}
        <path
          d={`M${points[0].x} ${points[0].y} C${points[1].x} ${points[1].y}, ${points[2].x} ${points[2].y}, ${points[3].x} ${points[3].y} Q${points[4].x} ${points[4].y}, ${points[5].x} ${points[5].y} Z`}
          fill="#ff8000"
        />

        {/* Render the points with images */}
        {points.map((point, index) => (
          <g key={index}>
            <circle cx={point.x} cy={point.y} r="5" fill="gray" />
            <image
              href={imageUrl}
              x={point.x - 10}
              y={point.y - 10}
              width="20"
              height="20"
            />
          </g>
        ))}
      </svg>
      <img
        src={imageUrlLogo}
        alt="Somadsi"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "35vw",
          height: "34vw",
          maxWidth: "500px",
          maxHeight: "480px",
          zIndex: 99999,
        }}
      />
    </div>
  );
};

export default ShapeWithMovingPoints;
