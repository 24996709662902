import React, { Component } from "react";
import { Container, ReturnButton } from "./styles/Offers.styled";
import { Link } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import toast, { Toaster } from "react-hot-toast";

export default class AddOffers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = (value) => {
    this.setState({ content: value });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    // Ensure that the Quill content is accessible through the state
    const { content } = this.state;

    // Construct the payload with form data
    const payload = {
      titre: event.target.titre.value,
      tag: event.target.tag.value,
      description: event.target.description.value,
      niveau: event.target.niveau.value,
      experience: event.target.experience.value,
      heure_travail: event.target.heure_travail.value,
      ville: event.target.ville.value,
      langue: event.target.langue.value,
      necessite: content, // Quill content
    };

    // Make the fetch request to the server
    fetch(
      "https://offers.somadsi.ma/wp-content/plugins/custom-offer-plugin/save-data.php",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json(); // Parse the JSON only if the response is OK
      })
      .then((data) => {
        // console.log(data.message);
        toast.success(data.message);
      })
      .catch((error) => {
        // Handle errors
        toast.error(error.message);
      });
  };

  render() {
    return (
      <Container>
        <Toaster />
        <Link to={"/offres"}>
          <ReturnButton>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
            >
              <g fill="none" fillRule="evenodd">
                <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                <path
                  fill="currentColor"
                  d="M2.614 5.426A1.5 1.5 0 0 1 4 4.5h10a7.5 7.5 0 1 1 0 15H5a1.5 1.5 0 0 1 0-3h9a4.5 4.5 0 1 0 0-9H7.621l.94.94a1.5 1.5 0 0 1-2.122 2.12l-3.5-3.5a1.5 1.5 0 0 1-.325-1.634"
                />
              </g>
            </svg>
          </ReturnButton>
        </Link>
        <h1>Ajouter une offre</h1>
        <form method="post" onSubmit={this.handleSubmit}>
          <div>
            <label htmlFor="titre">Titre</label>
            <input type="text" id="titre" name="titre" required />
          </div>
          <div>
            <label htmlFor="tag">Tag</label>
            <input type="text" id="tag" name="tag" required />
          </div>
          <div>
            <label htmlFor="description">Description</label>
            <textarea
              id="description"
              name="description"
              rows="3"
              required
            ></textarea>
          </div>
          <div>
            <label htmlFor="niveau">Niveau</label>
            <input type="text" id="niveau" name="niveau" />
          </div>
          <div>
            <label htmlFor="experience">Experience</label>
            <input type="text" id="experience" name="experience" />
          </div>
          <div>
            <label htmlFor="heure_travail">Heure Travail</label>
            <input type="text" id="heure_travail" name="heure_travail" />
          </div>
          <div>
            <label htmlFor="ville">Ville</label>
            <input type="text" id="ville" name="ville" />
          </div>
          <div>
            <label htmlFor="langue">Langue</label>
            <input type="text" id="langue" name="langue" />
          </div>
          <div>
            <label>Necessite</label>
            <div id="necessite" name="necessite">
              <ReactQuill
                theme="snow" // You can choose different themes like 'snow' or 'bubble'
                value={this.state.content}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="save-div">
            <button type="submit" id="save">
              <svg
                id="icon"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <g fill="none" stroke="white" strokeWidth="2">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 21h10a4 4 0 0 0 4-4V7.414a1 1 0 0 0-.293-.707l-3.414-3.414A1 1 0 0 0 16.586 3H7a4 4 0 0 0-4 4v10a4 4 0 0 0 4 4"
                  />
                  <path d="M9 3h6v3a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1zm8 18v-7a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1v7" />
                  <path strokeLinecap="round" d="M11 17h2" />
                </g>
              </svg>
              Enregistrer
            </button>
          </div>
        </form>
      </Container>
    );
  }
}
