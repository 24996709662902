/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { BodyContainer, SignContainer } from "./styles/Offers.styled";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import emailjs from "emailjs-com";

const Sign = () => {
  const [showLogin, setShowLogin] = useState(true);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLoginClick = () => {
    setShowLogin(true);
  };

  const handleSignUpClick = () => {
    setShowLogin(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "username") {
      setUsername(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!showLogin) {
      sendVerificationEmail(username, password);
    } else {
      const url =
        "https://offers.somadsi.ma/wp-content/plugins/custom-offer-plugin/login.php";
      axios

        .post(
          url,
          { username, password },
          { headers: { "Content-Type": "multipart/form-data" } }
        )
        .then((response) => {
          if (response.data.success) {
            const token = response.data.token;
            localStorage.setItem("token", token);

            // Mark that the user has logged in
            localStorage.setItem("hasUserLoggedBefore", "true");
            window.location.reload();
          } else {
            toast.error(response.data.message);
            // alert(response.data.message);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  // const hashPassword = async (password) => {
  //   const encoder = new TextEncoder();
  //   const data = encoder.encode(password);

  //   const hashBuffer = await crypto.subtle.digest("SHA-256", data);
  //   const hashArray = Array.from(new Uint8Array(hashBuffer));
  //   const hashedPassword = hashArray
  //     .map((byte) => byte.toString(16).padStart(2, "0"))
  //     .join("");

  //   return hashedPassword;
  // };

  const sendVerificationEmail = async (username, password) => {
    console.log("sendVerificationEmail");

    //const hashedPassword = await hashPassword(password);

    // Save user data in the database
    saveUserDataInDatabase(username, password);

    const verificationLink = `http://offers.somadsi.ma/verify?username=${username}`;
    // Use Email.js to send an email
    emailjs
      .send(
        "service_inuv7q9",
        "template_fzkoe7m",
        {
          username: "assistant@somadsi.ma",
          subject: "Verify your account",
          verificationLink: verificationLink,
        },
        "U_MYLwM3McL0v7eXv"
      )
      .then(() => {
        toast.success("Votre message a été envoyé avec succès");
      })
      .catch(() => {
        toast.error("L'envoi de votre message a échoué.");
        // console.error("Email failed to send:", error);
      });
  };

  const saveUserDataInDatabase = (username, password) => {
    // Make a request to your PHP file to save user data in the database
    return fetch(
      "https://offers.somadsi.ma/wp-content/plugins/custom-offer-plugin/verify.php",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: JSON.stringify({
          username: username,
          password: password,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Data saved in the database:", data);
        return Promise.resolve();
      })
      .catch((error) => {
        console.error("Error saving data:", error);
        return Promise.reject(error);
      });
  };

  useEffect(() => {
    // Check if the user is already logged in
    const hasUserLoggedBefore = localStorage.getItem("hasUserLoggedBefore");
    if (hasUserLoggedBefore) {
      navigate("/offres");
    }

    // Set initial styles
    handleLoginClick();
  }, [navigate]);

  return (
    <BodyContainer>
      <Toaster />
      <SignContainer>
        <div className="sign-header">
          <a className="btn-sign" id="btn-login" onClick={handleLoginClick}>
            <p id="p-login">Se connecter</p>
          </a>
          <a className="btn-sign" id="btn-sign" onClick={handleSignUpClick}>
            <p id="p-sign">S'inscrire</p>
          </a>
        </div>
        <form onSubmit={handleSubmit} action="#" method="post">
          <div className={showLogin ? "login-container" : "signup-container"}>
            <h2>{showLogin ? "Welcome Back!" : "Welcome!"}</h2>

            <div className={showLogin ? "login-wrapper" : "signup-wrapper"}>
              <p className="input-label">Username</p>
              <input
                placeholder="Username..."
                type="text"
                required
                className={showLogin ? "login-input" : "signup-input"}
                name="username"
                value={username}
                onChange={handleInputChange}
              />

              <p className="input-label">Password</p>
              <input
                placeholder="Password..."
                type="password"
                required
                className={showLogin ? "login-input" : "signup-input"}
                name="password"
                value={password}
                onChange={handleInputChange}
              />

              <button
                className={showLogin ? "login-button" : "signup-button"}
                type="submit"
              >
                {showLogin ? "Connexion" : "Enregistrer"}
              </button>
            </div>
          </div>
        </form>
      </SignContainer>
    </BodyContainer>
  );
};

export default Sign;
