import React from "react";
import ShapeWithMovingPoints from "./Blob";

const Dashboard = () => {
  return (
    <div>
      <ShapeWithMovingPoints />
    </div>
  );
};

export default Dashboard;
