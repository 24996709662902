import React, { useState, useEffect } from "react";
import { Container, ReturnButton } from "./styles/Offers.styled";
import { Link, useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const EditOffer = (props) => {
  const [selectedIds] = useState(props || []);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    titre: "",
    tag: "",
    description: "",
    niveau: "",
    experience: "",
    heure_travail: "",
    ville: "",
    langue: "",
    necessite: "",
  });

  useEffect(() => {
    const loadData = async () => {
      try {
        if (!selectedIds.id) {
          console.error("ID not found in session storage : " + selectedIds.id);
          return;
        } else {
          console.log("Id : " + selectedIds.id);
        }

        const url = `https://offers.somadsi.ma/wp-content/plugins/custom-offer-plugin/fetch-data.php?param=offers&id=${selectedIds.id}`;

        const response = await fetch(url);

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const dataArray = await response.json();

        if (dataArray.length > 0) {
          const data = dataArray[0];

          setFormData({
            titre: data.titre || "",
            tag: data.tag || "",
            description: data.description || "",
            niveau: data.niveau || "",
            experience: data.experience || "",
            heure_travail: data.heure_travail || "",
            ville: data.ville || "",
            langue: data.langue || "",
            necessite: data.necessite || "",
          });
        } else {
          console.error("Empty data array received.");
        }
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    };

    loadData();
  }, [selectedIds.id]);

  const sendModifiedData = (modifiedData) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(modifiedData),
      };

      axios
        .post(
          `https://offers.somadsi.ma/wp-content/plugins/custom-offer-plugin/edit-data.php?id=${selectedIds.id}`,
          requestOptions.body,
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        )
        .then((response) => {
          // Handle the response from the server if needed

          console.log(response.data);
          navigate("/offres");
        })
        .catch((error) => {
          // Handle errors
          toast.error(error.message);
        });
    } catch (error) {
      console.error("There was an error:", error);
    }
  };

  const handleSave = (e) => {
    e.preventDefault();
    // Get modified data from the form
    const modifiedData = {
      titre: formData.titre,
      tag: formData.tag,
      description: formData.description,
      niveau: formData.niveau,
      experience: formData.experience,
      heure_travail: formData.heure_travail,
      ville: formData.ville,
      langue: formData.langue,
      necessite: formData.necessite,
    };

    // Call the function to send modified data to the server
    sendModifiedData(modifiedData);
  };

  if (!selectedIds.id) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      <Toaster />
      <Link to={"/offres"}>
        <ReturnButton>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
          >
            <g fill="none" fillRule="evenodd">
              <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
              <path
                fill="currentColor"
                d="M2.614 5.426A1.5 1.5 0 0 1 4 4.5h10a7.5 7.5 0 1 1 0 15H5a1.5 1.5 0 0 1 0-3h9a4.5 4.5 0 1 0 0-9H7.621l.94.94a1.5 1.5 0 0 1-2.122 2.12l-3.5-3.5a1.5 1.5 0 0 1-.325-1.634"
              />
            </g>
          </svg>
        </ReturnButton>
      </Link>
      <h1>Modifier une offre</h1>
      <form
        method="post"
        onSubmit={(e) => {
          handleSave(e);
        }}
      >
        <div>
          <label htmlFor="titre">Titre</label>
          <input
            type="text"
            id="titre"
            name="titre"
            required
            value={formData.titre} // Populate the value from state
            onChange={(e) =>
              setFormData({ ...formData, titre: e.target.value })
            }
          />
        </div>
        <div>
          <label htmlFor="tag">Tag</label>
          <input
            type="text"
            id="tag"
            name="tag"
            required
            value={formData.tag} // Populate the value from state
            onChange={(e) => setFormData({ ...formData, tag: e.target.value })}
          />
        </div>
        <div>
          <label htmlFor="description">Description</label>
          <textarea
            id="description"
            name="description"
            rows="3"
            required
            value={formData.description} // Populate the value from state
            onChange={(e) =>
              setFormData({ ...formData, description: e.target.value })
            }
          ></textarea>
        </div>
        <div>
          <label htmlFor="niveau">Niveau</label>
          <input
            type="text"
            id="niveau"
            name="niveau"
            value={formData.niveau} // Populate the value from state
            onChange={(e) =>
              setFormData({ ...formData, niveau: e.target.value })
            }
          />
        </div>
        <div>
          <label htmlFor="experience">Experience</label>
          <input
            type="text"
            id="experience"
            name="experience"
            value={formData.experience} // Populate the value from state
            onChange={(e) =>
              setFormData({ ...formData, experience: e.target.value })
            }
          />
        </div>
        <div>
          <label htmlFor="heure_travail">Heure Travail</label>
          <input
            type="text"
            id="heure_travail"
            name="heure_travail"
            value={formData.heure_travail} // Populate the value from state
            onChange={(e) =>
              setFormData({ ...formData, heure_travail: e.target.value })
            }
          />
        </div>
        <div>
          <label htmlFor="ville">Ville</label>
          <input
            type="text"
            id="ville"
            name="ville"
            value={formData.ville} // Populate the value from state
            onChange={(e) =>
              setFormData({ ...formData, ville: e.target.value })
            }
          />
        </div>
        <div>
          <label htmlFor="langue">Langue</label>
          <input
            type="text"
            id="langue"
            name="langue"
            value={formData.langue} // Populate the value from state
            onChange={(e) =>
              setFormData({ ...formData, langue: e.target.value })
            }
          />
        </div>
        <div>
          <label>Necessite</label>
          <div id="necessite" name="necessite">
            <ReactQuill
              theme="snow" // You can choose different themes like 'snow' or 'bubble'
              value={formData.necessite}
              onChange={(value) =>
                setFormData({ ...formData, necessite: value })
              }
            />
          </div>
        </div>
        <div className="save-div">
          <button type="submit" id="save">
            <svg
              id="icon"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <g fill="none" stroke="white" strokeWidth="2">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M7 21h10a4 4 0 0 0 4-4V7.414a1 1 0 0 0-.293-.707l-3.414-3.414A1 1 0 0 0 16.586 3H7a4 4 0 0 0-4 4v10a4 4 0 0 0 4 4"
                />
                <path d="M9 3h6v3a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1zm8 18v-7a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1v7" />
                <path strokeLinecap="round" d="M11 17h2" />
              </g>
            </svg>
            Enregistrer
          </button>
        </div>
      </form>
    </Container>
  );
};

export default EditOffer;
